import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Text,
  View,
  TextInput,
  Platform,
  StyleSheet,
  ScrollView,
  Picker,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import UploadPhotoBox from "../../components/UploadPhotoBox";
import { firebaseApp } from "../../firebase/config";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  doc,
  collection,
  getFirestore,
  addDoc,
  Timestamp,
  setDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import {
  isNetworkConnectionAvailable,
  checkAndAlertUserIfNoNetworkConnection,
} from "../../utils/helpers/helpers";
import { uploadImagesLocally } from "../../utils/helpers/helpers";

const QualityControlWizardForm = ({ navigation, route }) => {
  const {
    selectedArea,
    selectedFloor,
    auditFormToComplete,
    selectedBuilding,
    scoringOptions,
  } = route.params;

  const [auditId, setAuditId] = useState(auditFormToComplete.doc_id);
  const [loading, setLoading] = useState(false);

  const userUid = useSelector((state) => state.auth.uid);

  const [areaBeingAuditted, setAreaBeingAuditted] = useState(
    auditFormToComplete.areas
      .find((area) => area.areaName === selectedArea)
      .items.map((item) => ({
        itemName: item,
        score: "",
        comments: "",
        imageUrls: "",
      }))
  );

  const db = getFirestore(firebaseApp);
  const storage = getStorage();
  const buildingRef = doc(db, "BuildingsX", selectedBuilding);
  const auditRef = collection(buildingRef, "audit", auditId, "responses");
  const [allScoresFilled, setAllScoresFilled] = useState(false);

  const pickerStyles = StyleSheet.create({
    pickerContainer: {
      borderColor: "lightgrey",
      borderWidth: 1,
      borderRadius: 10,
      backgroundColor: "#fff",
      paddingHorizontal: 10,
      height: 40,
    },
    picker: {
      flex: 1,
    },
    pickerItem: {
      fontSize: 16,
    },
  });

  useEffect(() => {
    checkAndAlertUserIfNoNetworkConnection();
  }, []);

  useEffect(() => {
    const areAllScoresFilled = areaBeingAuditted.every(
      (item) => item.score !== ""
    );
    setAllScoresFilled(areAllScoresFilled);
  }, [areaBeingAuditted]);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry");
        }
      }
    })();
  }, []);

  const filteredArea = auditFormToComplete.areas.find(
    (area) => area.areaName === selectedArea
  );
  const filteredFloor = filteredArea.floors.includes(selectedFloor)
    ? selectedFloor
    : null;

  const handleQuestionChange = (index, property, value) => {
    const updatedAreaBeingAuditted = [...areaBeingAuditted];
    updatedAreaBeingAuditted[index][property] = value;
    setAreaBeingAuditted(updatedAreaBeingAuditted);
  };

  const handleImageChange = (index, newImages) => {
    const updatedAreaBeingAuditted = [...areaBeingAuditted];
    updatedAreaBeingAuditted[index].image = newImages;
    setAreaBeingAuditted(updatedAreaBeingAuditted);
  };

  const pickImage = async (index) => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      handleImageChange(
        index,
        `data:image/jpeg;base64,${result.assets[0].base64}`
      );
    }
  };

  const submitHandler = async () => {
    const tempIdForLocalStorage = Date.now().toString();
    try {
      setLoading(true);
      const docToBeSaved = doc(auditRef);
      const responseData = {
        areaName: selectedArea,
        floor: selectedFloor,
        createdAt: Timestamp.fromDate(new Date()),
        items: isNetworkConnectionAvailable()
          ? await Promise.all(
              areaBeingAuditted.map(async (question) => {
                let imageDownloadUrl = null;

                if (question.image) {
                  imageDownloadUrl = await uploadImageAndGetDownloadUrl(
                    question.image,
                    question.itemName
                  );
                }

                const score =
                  question.score === null || question.score === "N/A"
                    ? question.score
                    : parseFloat(question.score);

                return {
                  itemName: question.itemName,
                  score: score,
                  comments: question.comments,
                  imageUrls: imageDownloadUrl ? [imageDownloadUrl] : [],
                };
              })
            )
          : await (async () => {
            const result = [];
            for (const question of areaBeingAuditted) {
              let imageDownloadUrl = null;
    
              if (question.image) {
                await uploadImagesLocally(
                  [question.image],
                  `${selectedBuilding}-${auditId}-${question.itemName}-${tempIdForLocalStorage}`
                );
              }
    
              const score =
                question.score === null || question.score === "N/A"
                  ? question.score
                  : parseFloat(question.score);
    
              result.push({
                itemName: question.itemName,
                score: score,
                comments: question.comments,
                imageUrls: imageDownloadUrl ? [imageDownloadUrl] : [],
              });
            }
            return result;
          })(),
        uid: userUid,
      };
      isNetworkConnectionAvailable()
        ? await setDoc(docToBeSaved, {
            ...responseData,
            doc_id: docToBeSaved.id,
          })
        : addDoc(auditRef, {
            ...responseData,
            tempIdForLocalStorage: tempIdForLocalStorage,
          });

      submitted();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: `Something went wrong!`,
        confirmButtonText: "OK",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const submitted = () => {
    const alertMessage = `Thank You! Your Audit Has Been Submitted`;

    if (Platform.OS === "web") {
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Thank You! Your Audit Has Been Submitted, ${
          isNetworkConnectionAvailable()
            ? ""
            : "Data will be uploaded once connection is restored."
        }`,
        confirmButtonText: "OK",
      });
      setLoading(false);
      navigation.navigate("Home");
    } else {
      Alert.alert("Done", alertMessage, [
        {
          text: "OK",
          onPress: () => {
            setLoading(false);
            navigation.navigate("Home");
          },
        },
      ]);
    }
  };

  const uploadImageAndGetDownloadUrl = async (image, itemName) => {
    const response = await fetch(image);
    const blob = await response.blob();

    const imageRef = ref(
      storage,
      `quality-control/${selectedBuilding}/${selectedFloor}/${selectedArea}/${itemName}`
    );

    const snapshot = await uploadBytes(imageRef, blob);
    const imageUrl = await getDownloadURL(snapshot.ref);
    return imageUrl;
  };

  if (loading)
    return (
      <ActivityIndicator style={{ flex: 1 }} size="large" color="#5e99fa" />
    );

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flex: 1 }}
      enableOnAndroid={true}
      automaticallyAdjustContentInsets={true}
      enableAutomaticScroll={Platform.OS === "ios"}
    >
      <ScrollView>
        {filteredFloor &&
          areaBeingAuditted.map((question, index) => {
            return (
              <View key={index} style={styles.container}>
                <Text style={styles.questionLabel}>{question.itemName}</Text>
                <Text style={{ marginTop: 16, marginBottom: 8 }}>
                  Score: (Required*)
                </Text>

                <View style={styles.buttonsContainer}>
                  <Picker
                    selectedValue={question.score}
                    onValueChange={(value) =>
                      handleQuestionChange(index, "score", value)
                    }
                    style={pickerStyles.pickerContainer}
                  >
                    <Picker.Item label="Select Score" value={null} />
                    {scoringOptions.map((score) => (
                      <Picker.Item key={score.value} {...score} />
                    ))}
                    <Picker.Item label="N/A" value="N/A" />
                  </Picker>
                </View>
                <TextInput
                  placeholder="Comment..."
                  multiline
                  style={styles.textInput}
                  value={question.comments}
                  onChangeText={(value) =>
                    handleQuestionChange(index, "comments", value)
                  }
                />

                <UploadPhotoBox
                  pickImage={() => pickImage(index)}
                  selectedImage={question.image}
                />
              </View>
            );
          })}

        <TouchableOpacity
          style={StyleSheet.compose(
            allScoresFilled
              ? dynamicStyles.enabledButtonBox
              : dynamicStyles.disabledButtonBox,
            styles.submitButtonBox
          )}
          onPress={submitHandler}
          disabled={!allScoresFilled}
        >
          <Text style={styles.submitButtonText}>Submit</Text>
        </TouchableOpacity>
      </ScrollView>
    </KeyboardAwareScrollView>
  );
};

const dynamicStyles = StyleSheet.create({
  enabledButtonBox: {
    backgroundColor: "#5e99fa",
  },
  disabledButtonBox: {
    backgroundColor: "rgba(94, 153, 250, 0.5)",
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 15,
    marginVertical: 20,
  },
  questionLabel: {
    fontSize: 20,
    marginBottom: 15,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  textInput: {
    borderWidth: 1,
    borderColor: "lightgrey",
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 10,
    height: 150,
  },

  submitButtonBox: {
    alignItems: "center",
    marginTop: 20,
    paddingVertical: 10,
    borderRadius: 5,
  },
  submitButtonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
});

export default QualityControlWizardForm;
