import React, { useState } from "react";
import {
  View,
  Text,
  ActionSheetIOS,
  Platform,
  TouchableOpacity,
  Alert,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSelector, useDispatch } from "react-redux";
import { getFormNames } from "../../utils/helpers/equipmentForms";
import { getFirestore, doc, collection, addDoc } from "firebase/firestore";
import { Picker } from "@react-native-picker/picker";
import { firebaseApp } from "../../firebase/config";
import { buildingListActions } from "../../store/building-slice";
import styles from "./styles";
import { EQUIPMENT_FORMS } from "../../utils/helpers/equipmentForms";
import WizardForm from "../../components/WizardForm";

const EquipmentForm = ({ route, navigation }) => {
  const [selectedForm, setSelectedForm] = useState("");
  const [selectedFormType, setSelectedFormType] = useState("");
  const [comment, setComment] = useState("");
  const formNames = getFormNames();
  const { siteId } = route.params;
  const date = new Date();
  const dateString = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  const userUid = useSelector((state) => state.auth.uid);
  const selectedBuilding = useSelector(
    (state) => state.buildingList.selectedBuilding
  );
  const siteIsAdhoc = selectedBuilding?.isAdhoc || false;
  const db = getFirestore(firebaseApp);
  const dispatch = useDispatch();

  const siteRef = doc(db, siteIsAdhoc ? 'adhocSites' : "BuildingsX", siteId);

  const questions =
    (!!selectedForm &&
      !!selectedFormType &&
      EQUIPMENT_FORMS[selectedForm][selectedFormType.toLocaleLowerCase()]
        .questions) ||
    [];

  const openFormMenuOnIos = () =>
    ActionSheetIOS.showActionSheetWithOptions(
      {
        options: ["Cancel", ...formNames],
        cancelButtonIndex: 0,
        userInterfaceStyle: "dark",
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          return setSelectedForm("");
        }
        setSelectedForm(formNames[buttonIndex - 1]);
      }
    );

  const submitFormHandler = async (form) => {
    const documentToAdd = {
      report: comment,
      date: date,
      machine: selectedForm,
      preUseChecks: true,
      questions: form.map(({ question, value, comments }) => ({
        question,
        value,
        comments: comments || "",
      })),
      type: "Access Equipment",
      accessFormType: selectedFormType.toLocaleLowerCase(),
      completedBy: userUid,
    };
    try {
      if (!selectedForm || !selectedFormType) {
        throw new Error("No form selected.");
      }
      await addDoc(collection(siteRef, "windowCleaningForms"), documentToAdd);
      const alertMessage = "Thanks, form successfully submitted.";

      if (selectedFormType === "Pre") {
        dispatch(buildingListActions.setEquipmentForm(true));
      }
      if (Platform.OS === "web") {
        alert(alertMessage);
        navigation.navigate("Building");
      } else {
        Alert.alert("Thanks", alertMessage, [
          { title: "OK", onPress: navigation.navigate("Building") },
        ]);
      }
    } catch (error) {
      if (Platform.OS === "web") {
        alert(error.message);
      } else {
        Alert.alert("Oops", error.message);
      }
    }
  };

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flex: 1 }}
      enableOnAndroid={true}
      automaticallyAdjustContentInsets={true}
      enableAutomaticScroll={Platform.OS === "ios"}
    >
      <View style={styles.container}>
        <Text style={styles.title}>{siteId}</Text>
        <Text style={styles.date}>{dateString}</Text>

        <View style={styles.section}>
          <Text style={styles.text}>Select form:</Text>

          {Platform.OS === "ios" ? (
            <TouchableOpacity
              onPress={openFormMenuOnIos}
              style={styles.iosButton}
            >
              <Text style={styles.iosButtonText}>
                {selectedForm ? selectedForm : "Please select"}
              </Text>
            </TouchableOpacity>
          ) : (
            <Picker
              style={styles.picker}
              selectedValue={selectedForm}
              onValueChange={(itemValue, itemIndex) => {
                setSelectedForm(itemValue);
              }}
            >
              <Picker.Item label="Please select" value="" />
              {formNames.map((d) => {
                return <Picker.Item key={d} label={d} value={d} />;
              })}
            </Picker>
          )}
        </View>

        {!!selectedForm && (
          <View style={styles.section}>
            <Text style={styles.text}>Select form type:</Text>
            <Picker
              style={styles.picker}
              selectedValue={selectedFormType}
              onValueChange={(itemValue, itemIndex) => {
                setSelectedFormType(itemValue);
              }}
            >
              <Picker.Item label="Please select" value="" />
              {["Pre", "Post"].map((d) => {
                return <Picker.Item key={d} label={d} value={d} />;
              })}
            </Picker>
          </View>
        )}

        {!!questions.length && (
          <WizardForm questions={questions} submitForm={submitFormHandler} />
        )}
      </View>
    </KeyboardAwareScrollView>
  );
};

export default EquipmentForm;
