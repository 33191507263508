const ukPhoneRegex = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;

export function isValidUKPhone(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\s/g, "");
  return ukPhoneRegex.test(phoneNumber);
}

export function formatUKPhoneNumber(number) {
  number = number.replace(/\s/g, "");
  if (number.startsWith("07")) {
    return `+44${number.slice(1)}`;
  } else {
    return number;
  }
}