import { useEffect, useState } from "react";
import {
  Text,
  View,
  TextInput,
  Platform,
  StyleSheet,
  Keyboard,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import WizardButton from "./WizardButton";

export default function WizardForm({ questions, submitForm }) {
  const [updatedQuestions, setUpdatedQuestions] = useState(questions);
  const [questionStep, setQuestionStep] = useState(0);

  const currentQuestion = updatedQuestions[questionStep];

  const responseIsValid = currentQuestion && (
    (currentQuestion.value && currentQuestion.value !== "No") ||
    (currentQuestion.value === "No" && currentQuestion.comments)
  );

  useEffect(() => {
    setUpdatedQuestions(questions);
    setQuestionStep(0);
  }, [questions]);

  const updateQuestionValue = (value) => {
    setUpdatedQuestions((prevQuestions) =>
      prevQuestions.map((q, index) =>
        index === questionStep ? { ...q, value } : q
      )
    );
  };

  const updateCommentToQuestion = (value) => {
    setUpdatedQuestions((prevQuestions) =>
      prevQuestions.map((q, index) =>
        index === questionStep ? { ...q, comments: value } : q
      )
    );
  };
  const handleNext = () => {
    Keyboard.dismiss();
    setQuestionStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setQuestionStep((prev) => prev - 1);
  };

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={styles.scrollContainer}
      enableOnAndroid
      enableAutomaticScroll={Platform.OS === "ios"}
    >
      <View style={styles.container}>
        <View style={styles.questionContainer}>
          <Text style={styles.questionLabel}>{currentQuestion.question}</Text>

          {!!currentQuestion?.multipleChoice && (
            <View style={styles.buttonsContainer}>
              {currentQuestion.multipleChoice.map((option) => (
                <WizardButton
                  key={option}
                  title={option}
                  value={currentQuestion.value}
                  onPress={() => updateQuestionValue(option)}
                />
              ))}
            </View>
          )}
          <TextInput
            placeholder="Type your response..."
            multiline
            textAlignVertical="top"
            returnKeyType="done"
            style={styles.textInput}
            value={currentQuestion.comments || ""}
            onChangeText={updateCommentToQuestion}
          />
        </View>

        <View style={styles.navigationButtons}>
          <WizardButton
            title="Back"
            onPress={handleBack}
            disabled={questionStep === 0}
          />
          {questionStep < questions.length - 1 ? (
            <WizardButton
              title="Next"
              disabled={!responseIsValid}
              onPress={handleNext}
            />
          ) : (
            <WizardButton
              title="Submit"
              onPress={() => submitForm(updatedQuestions)}
            />
          )}
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 25,
  },
  questionLabel: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 12,
  },
  questionContainer: {
    flex: 1,
    justifyContent: "center",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 10,
    marginBottom: 10
  },
  textInput: {
    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#fff",
    padding: 12,
    borderRadius: 8,
    minHeight: 120,
  },
  navigationButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
});
