import Swal from "sweetalert2";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SINGLE_WINDOW_SITES = Object.freeze([
  "20 Fenchurch Street",
  "110 Bishopsgate",
  "40 Leadenhall",
  "2 Kingdom Street",
  "4 Kingdom Street",
  "3 Sheldon Square",
  "Paddington Square",
  "22 Bishopsgate",
]);

const CLEAN_NAMES = Object.freeze([
  { id: "cleanOne", name: "Clean One" },
  { id: "cleanTwo", name: "Clean Two" },
  { id: "cleanThree", name: "Clean Three" },
  { id: "cleanFour", name: "Clean Four" },
  { id: "cleanFive", name: "Clean Five" },
  { id: "cleanSix", name: "Clean Six" },
  { id: "cleanSeven", name: "Clean Seven" },
  { id: "cleanEight", name: "Clean Eight" },
  { id: "cleanNine", name: "Clean Nine" },
  { id: "cleanTen", name: "Clean Ten" },
]);

const scoreCriteriaColors = [
  { label: "Green", value: "green" },
  { label: "Red", value: "red" },
  { label: "Orange", value: "orange" },
  { label: "Yellow", value: "yellow" },
  { label: "Blue", value: "blue" },
  { label: "Purple", value: "purple" },
];

export function getCleanNames(numberOfCleans) {
  const cleanNames = [];
  for (let i = 0; i < numberOfCleans; i++) {
    cleanNames.push(CLEAN_NAMES[i].name);
  }
  return cleanNames;
}

export function getCleanNameFromId(id) {
  try {
    const cleanIndex = CLEAN_NAMES.findIndex((clean) => clean.id === id);
    if (cleanIndex === -1) {
      throw new Error(
        "An Error has occurred. Please try again or contact us if the error persists."
      );
    }
    return CLEAN_NAMES[cleanIndex].name;
  } catch (error) {
    console.log(error);
  }
}

export function getCleanIdFromName(name) {
  try {
    const cleanIndex = CLEAN_NAMES.findIndex((clean) => clean.name === name);
    if (cleanIndex === -1) {
      throw new Error(
        "An Error has occurred. Please try again or contant us if the error persists."
      );
    }
    return CLEAN_NAMES[cleanIndex].id;
  } catch (error) {
    console.log(error);
  }
}

export const isNetworkConnectionAvailable = () => {
  if (navigator.onLine) return true;
  return false;
};

export const checkAndAlertUserIfNoNetworkConnection = () => {
  if (!isNetworkConnectionAvailable()) {
    return Swal.fire({
      icon: "info",
      title: "No Network Connection",
      text: `You appear to be offline. Data submitted will be uploaded when connection is restored. Please open the app when you have a connection for the process to complete.`,
      confirmButtonText: "OK",
    });
  }
};

export const uploadImagesLocally = async (images, firebaseRef) => {
  try {
    const [siteId, auditId, itemName] = firebaseRef.split("-");
    const keyForLocalStorage = `qc_${siteId}_${auditId}`;
    const localStorageKeys = await AsyncStorage.getAllKeys();

    if (localStorageKeys.includes(keyForLocalStorage)) {
      const valueFromLocalStorage = JSON.parse(
        await AsyncStorage.getItem(keyForLocalStorage)
      );
      console.log(valueFromLocalStorage);
      valueFromLocalStorage.images.push({ itemName: itemName, images: images });
      await AsyncStorage.setItem(
        keyForLocalStorage,
        JSON.stringify(valueFromLocalStorage)
      );
    } else {
      await AsyncStorage.setItem(
        keyForLocalStorage,
        JSON.stringify({
          firebaseRef: firebaseRef,
          images: [{ itemName: itemName, images: images }],
        })
      );
    }
    return true;
  } catch (error) {
    console.error("Error storing images locally:", error);
    return false;
  }
};

export const base64ToBlob = (base64String) => {
  const base64Parts = base64String.split(",");
  const contentType = base64Parts[0].split(":")[1];
  const base64Data = base64Parts[1];

  const binaryData = atob(base64Data);

  const uint8Array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: contentType });
};

export const calculateTitleAndScoreLabel = (customScoreCriteria, type) => {
  const numberToBeMultiplied = type === "forDisplay" ? 100 : 1;
  let prevWeight = 0;

  return [...customScoreCriteria]
    .sort((a, b) => a.weight - b.weight)
    .map((item, i) => {
      const obj = {
        ...item,
        title:
          i === 0
            ? `0% - ${(item.weight * numberToBeMultiplied).toFixed(0)}%`
            : `${(prevWeight * numberToBeMultiplied).toFixed(0)}% - ${(
                item.weight * numberToBeMultiplied
              ).toFixed(0)}%`,
        scoreLabel: String(i + 1),
        weight: type === "total" ? item.weight / 100 : item.weight,
        color:
          type === "total"
            ? item.color
            : scoreCriteriaColors.find((c) => c.value === item.color),
      };

      prevWeight = item.weight;

      return obj;
    });
};
