import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage();

export const uploadImage = async (mediaArray, equipmentId = "") => {
  const uploadPromises = mediaArray.map(async (media) => {
    try {
      const response = await fetch(media);
      const blob = await response.blob();

      const mediaRef = ref(
        storage,
        `window-cleaning/defects${new Date().getTime()}_` + equipmentId
      );

      const snapshot = await uploadBytes(mediaRef, blob);
      const mediaUrl = await getDownloadURL(snapshot.ref);
      return mediaUrl;
    } catch (error) {
      console.log(error);
      return null;
    }
  });

  const mediaUrls = await Promise.all(uploadPromises);
  return mediaUrls.filter((url) => url !== null);
};
