import Constants from "expo-constants";
const { manifest } = Constants;

const NODE_ENVIRONMENTS = Object.freeze({
  PRODUCTION: "production",
  DEVELOPMENT: "development",
});

const HOST_NAMES = Object.freeze({
  PRODUCTION_SERVER: "wc.principle-clean.com",
  DEVELOPMENT_SERVER: "qc-offline.web.app",
  DEVELOPMENT_LOCAL: "localhost",
});

const ENVIRONMENTS = Object.freeze({
  PRODUCTION_SERVER: "productionServer",
  DEVELOPMENT_SERVER: "developmentServer",
  DEVELOPMENT_LOCAL: "developmentLocal",
});

function getEnvironment() {
  const isRunningAProductionBuildOnTheServer =
    process.env.NODE_ENV === "production" && window.location.hostname === HOST_NAMES.PRODUCTION_SERVER;

    const isRunningADeployedBuildOnTheDevServer = NODE_ENVIRONMENTS.PRODUCTION && window.location.hostname === HOST_NAMES.DEVELOPMENT_SERVER

  if (isRunningAProductionBuildOnTheServer) {
    return ENVIRONMENTS.PRODUCTION;
  }

  const inExpo = Constants.manifest && !!Constants.manifest.debuggerHost;
  const inBrowser = typeof document !== "undefined";

  const isRunningOnLocalMachine =
    process.env.NODE_ENV === "development" && (inExpo || inBrowser);

    if (isRunningAProductionBuildOnTheServer) {
      return ENVIRONMENTS.PRODUCTION_SERVER;
    } else if (isRunningADeployedBuildOnTheDevServer) {
      return ENVIRONMENTS.DEVELOPMENT_SERVER
    } else if (isRunningOnLocalMachine) {
      return ENVIRONMENTS.DEVELOPMENT_LOCAL;
    }
  // Its important to throw an error here. We don't want to be using live when we shouldn't, and we
  // really don't want users to be using dev when they shouldn't.
  throw new Error("Can't get user environment! Aborting.");
}

function isInProductionEnvironment() {
  return getEnvironment() === ENVIRONMENTS.PRODUCTION;
}

export { isInProductionEnvironment };
