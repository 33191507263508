import { View, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { collection, getFirestore, addDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../../firebase/config";
import WizardForm from "../../components/WizardForm";
import { getPPEFormQuestions } from "../../utils/helpers/equipmentForms";

export default function PPEFormScreen({ route, navigation }) {
  const date = new Date();
  const userUid = useSelector((state) => state.auth.uid);
  const { siteId } = route.params;
  const db = getFirestore(firebaseApp);
  const selectedBuilding = useSelector(
    (state) => state.buildingList.selectedBuilding
  );
  const siteIsAdhoc = selectedBuilding?.isAdhoc || false;

  const questions = getPPEFormQuestions();

  const siteRef = doc(db, siteIsAdhoc ? "adhocSites" : "BuildingsX", siteId);

  const formsRef = collection(siteRef, "windowCleaningForms");

  const submitForm = async (questions) => {
    try {
      const cleanedQuestions = questions.map((q) => {
        return {
          question: q.question,
          value: q.value,
          comments: q.comments || "",
        };
      });
      const docToSave = {
        response: cleanedQuestions,
        completedBy: userUid,
        date: date,
        type: "PPE",
      };

      await addDoc(formsRef, docToSave);

      alert("Thanks. Form has been saved.");
      navigation.navigate("Building");
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <WizardForm questions={questions} submitForm={submitForm} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
  },
});
