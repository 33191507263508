import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ActionSheetIOS,
  Platform,
  TouchableOpacity,
  TextInput,
  Alert,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSelector } from "react-redux";
import * as ImagePicker from "expo-image-picker";
import { Picker } from "@react-native-picker/picker";
import {
  getFirestore,
  doc,
  collection,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firebaseApp } from "../../firebase/config";
import UploadPhotoBox from "../../components/UploadPhotoBox";
import { uploadImage } from "../../utils/helpers/functions";
import Swal from "sweetalert2";

const DefectReportingFormScreen = ({ route, navigation }) => {
  const [selectedEquipment, setSelectedEquipment] = useState("");
  const [summary, setSummary] = useState("");
  const [equipmentNewServiceStatus, setEquipmentNewServiceStatus] =
    useState(false);
  const [allEquipment, setAllEquipment] = useState([]);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const currentClean = useSelector((state) => state.buildingList.currentClean);

  const userUid = useSelector((state) => state.auth.uid);

  const { siteId } = route.params;
  const date = new Date();
  const dateString = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const db = getFirestore(firebaseApp);
  const siteRef = doc(db, "BuildingsX", siteId);

  const equipmentRef = collection(siteRef, "equipment");
  const defectsRef = collection(siteRef, "defects");

  const isEquipment = !(
    selectedEquipment === "" || selectedEquipment === "building"
  );

  const selectedEquipmentData = allEquipment.find(
    (eq) => eq.id === selectedEquipment
  );

  const isInService = selectedEquipmentData.inService;
  const labelForEquipmentServiceBox = isInService
    ? "Not in service?"
    : "In service?";

  const equipmentStatus =
    isInService && equipmentNewServiceStatus
      ? true
      : isInService && !equipmentNewServiceStatus
      ? false
      : !isInService && equipmentNewServiceStatus
      ? true
      : false;

  useEffect(() => {
    getDefectOptions();
  }, []);

  const getDefectOptions = async () => {
    try {
      const allEquipment = await getDocs(equipmentRef);
      const equipmentArray = [];
      const buildingDefectOption = {
        id: "building",
        name: "Building",
        type: "building",
      };
      if (!allEquipment.empty) {
        allEquipment.forEach((eq) => {
          equipmentArray.push({ id: eq.id, ...eq.data() });
        });
      }
      setAllEquipment([...equipmentArray, buildingDefectOption]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const openFormMenuOnIos = () =>
    ActionSheetIOS.showActionSheetWithOptions(
      {
        options: ["Cancel", ...allEquipment.map((e) => e.name)],
        cancelButtonIndex: 0,
        userInterfaceStyle: "dark",
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          return setSelectedEquipment("");
        }
        setSelectedEquipment(allEquipment[buttonIndex - 1].id);
      }
    );

  const pickMedia = async () => {
    if (media.length >= 5) {
      return Swal.fire({
        title: "Oops",
        text: "You can only upload a maximum of 5 files.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#5e99fa",
      });
    }
    const selectedFiles = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsMultipleSelection: true,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      multiple: true,
      videoMaxDuration: 20,
    });

    const filteredFiles = selectedFiles.assets.filter(
      (file) => !file.uri.endsWith(".svg") && !file.uri.endsWith(".mp3")
    );

    if (!selectedFiles.canceled && filteredFiles.length) {
      setMedia([...media, ...filteredFiles.map((file) => file.uri)]);
    }
  };

  const submitFormHandler = async () => {
    try {
      if (!selectedEquipment || !summary || !media.length) {
        throw new Error("Please make sure all fields are provided.");
      }

      const userResponse = await Swal.fire({
        title: "Are you sure?",
        text: `You are about to put the equipment ${
          equipmentStatus ? "back to" : "out of"
        } service.  Are you sure?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#5e99fa",
        cancelButtonColor: "#d33",
      });

      if (!userResponse.isConfirmed) return;

      setSubmitting(true);

      const uploadedMediaUrl = await uploadImage(
        media,
        selectedEquipmentData.id
      );

      const documentToAdd = {
        defectReportDate: date,
        fixDate: null,
        defectType: selectedEquipmentData.type,
        inService: equipmentStatus,
        summary: summary,
        images: uploadedMediaUrl,
        completedBy: userUid,
        equipmentId: selectedEquipmentData.id,
        cleanId: currentClean,
      };
      const docRef = doc(defectsRef);

      await setDoc(docRef, documentToAdd);

      if (selectedEquipmentData.type !== "building") {
        await updateDoc(doc(equipmentRef, selectedEquipmentData.id), {
          inService: equipmentStatus,
          lastReportId: docRef.id,
        });
      }

      setEquipmentNewServiceStatus(false);
      setSelectedEquipment("");
      setSummary("");
      setSubmitting(false);

      const alertMessage = "Thanks, report has been successfully submitted.";

      if (Platform.OS === "web") {
        Swal.fire({
          title: "Thanks",
          text: alertMessage,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#5e99fa",
        });
        navigation.navigate("Building");
      } else {
        Alert.alert("Thanks", alertMessage, [
          { title: "OK", onPress: navigation.navigate("Building") },
        ]);
      }
    } catch (error) {
      if (Platform.OS === "web") {
        alert(error.message);
      } else {
        Alert.alert("Oops", error.message);
      }
    }
  };

  function isValid() {
    if (selectedEquipment !== "" && summary !== "" && media.length !== "")
      return true;
    return false;
  }

  if (loading)
    return (
      <ActivityIndicator style={{ flex: 1 }} size="large" color="#5e99fa" />
    );

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flex: 1 }}
      enableOnAndroid={true}
      automaticallyAdjustContentInsets={true}
      enableAutomaticScroll={Platform.OS === "ios"}
    >
      <View style={styles.container}>
        <Text style={styles.title}>{siteId}</Text>
        <Text style={styles.date}>{dateString}</Text>

        <View style={styles.section}>
          <Text style={styles.text}>Please select:</Text>

          {Platform.OS === "ios" ? (
            <TouchableOpacity
              onPress={openFormMenuOnIos}
              style={styles.iosButton}
            >
              <Text style={styles.iosButtonText}>
                {selectedEquipment ? selectedEquipment : "Please select"}
              </Text>
            </TouchableOpacity>
          ) : (
            <Picker
              style={styles.picker}
              selectedValue={selectedEquipment}
              onValueChange={(itemValue, itemIndex) => {
                setSelectedEquipment(itemValue);
              }}
            >
              <Picker.Item label="Please select" value="" />
              {allEquipment.map((d) => {
                return <Picker.Item key={d.id} label={d.name} value={d.id} />;
              })}
            </Picker>
          )}
        </View>
        {isEquipment && (
          <>
            {selectedEquipmentData && (
              <Text>
                Equipment is currently{" "}
                <Text
                  style={{
                    color: isInService ? "green" : "red",
                  }}
                >
                  {labelForEquipmentServiceBox}
                </Text>
                .
              </Text>
            )}
            <View style={styles.outOfServiceBox}>
              <TouchableOpacity
                style={styles.radioButton}
                onPress={() =>
                  setEquipmentNewServiceStatus(!equipmentNewServiceStatus)
                }
              >
                <Text>{labelForEquipmentServiceBox}</Text>
                {equipmentNewServiceStatus && (
                  <AntDesign
                    name="check"
                    size={20}
                    color="black"
                    style={{ alignSelf: "center" }}
                  />
                )}
              </TouchableOpacity>
            </View>
          </>
        )}
        <TextInput
          style={styles.summaryInput}
          placeholder="Summary"
          value={summary}
          numberOfLines={12}
          multiline
          textAlignVertical="top"
          returnKeyType="done"
          onChangeText={setSummary}
        />

        <UploadPhotoBox pickMedia={pickMedia} media={media} />

        {!!media.length && (
          <TouchableOpacity onPress={() => setMedia([])}>
            <Text>Clear media</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{
            ...styles.submitButton,
            backgroundColor: isValid() ? "#5e99fa" : "grey",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
          }}
          disabled={!isValid()}
          onPress={submitFormHandler}
        >
          <Text style={styles.submitButtonText}>Submit</Text>
          {submitting && <ActivityIndicator color="#fff" size="small" />}
        </TouchableOpacity>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
    textAlign: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 30,
  },
  date: {
    fontSize: 20,
    fontStyle: "italic",
  },
  section: {
    marginVertical: 10,
    marginHorizontal: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  picker: {
    width: 200,
    height: 50,
    borderRadius: 10,
    marginTop: 5,
    color: "black",
    backgroundColor: "#F6F6F6",
  },
  iosButton: {
    width: 200,
    height: 50,
    borderRadius: 10,
    marginTop: 5,
    backgroundColor: "#F6F6F6",
    justifyContent: "center",
  },
  iosButtonText: {
    color: "black",
    paddingHorizontal: 10,
  },
  submitButton: {
    height: 59,
    borderRadius: 10,
    justifyContent: "center",
    width: 200,
    marginTop: 20,
    alignSelf: "center",
  },
  submitButtonText: {
    color: "#fff",
    alignSelf: "center",
  },
  summaryInput: {
    width: 300,
    height: 100,
    borderRadius: 10,
    backgroundColor: "white",
    marginTop: 10,
    borderColor: "#E8EAE6",
    borderWidth: 1,
    padding: 10,
    alignSelf: "center",
  },
  radioButton: {
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: "#000",
    justifyContent: "flex-start",
  },
  outOfServiceBox: {
    width: 290,
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
  },
});

export default DefectReportingFormScreen;
