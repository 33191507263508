import { TouchableOpacity, Text, StyleSheet } from "react-native";

export default function WizardButton({ title, value, disabled = false, onPress }) {
    const isSelected = value === title;
    
    return (
        <TouchableOpacity
            disabled={disabled}
            activeOpacity={0.7}
            style={[
                styles.container,
                { 
                    backgroundColor: isSelected ? "green" : disabled ? "lightgrey" : "#fff",
                    borderColor: isSelected ? "green" : "grey",
                }
            ]}
            onPress={onPress}
        >
            <Text style={[styles.text, { color: isSelected ? "#fff" : "#000" }]}>
                {title}
            </Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 70,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: 10,
        marginHorizontal: 5,
    },
    text: {
        fontSize: 14,
        fontWeight: "bold",
    },
});
