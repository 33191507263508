import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  ActivityIndicator,
  Switch,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import ViewBuildingCard from "../../components/ViewBuildingCard";
import styles from "./styles";
import { buildingListActions } from "../../store/building-slice";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { firebaseApp } from "../../firebase/config";
import { getAdHocSites } from "../../api/dropmarking";

export default function HomeScreen({ navigation }) {
  const dispatch = useDispatch();
  const db = getFirestore(firebaseApp);
  const accountsAccess = useSelector(
    (state) => state.buildingList.allBuildings
  );

  const [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchedBuilding, setSearchedBuilding] = useState("");
  const [filterAdhocSites, setFilterAdhocSites] = useState(false);

  const adhocFilteredBuildings = buildings.filter(
    (building) => building.isAdhoc
  );

  const filteredList = filterAdhocSites
    ? adhocFilteredBuildings
    : searchedBuilding
    ? buildings.filter((building) =>
        building.site_name
          .toLowerCase()
          .includes(searchedBuilding.toLowerCase())
      )
    : buildings;

  useEffect(() => {
    if (!accountsAccess.length) {
      setLoading(false);
      return;
    }
    setAllBuildings();
  }, [accountsAccess?.length]);

  const setAllBuildings = async () => {
    try {
      const promises = accountsAccess.map(async (account) => {
        const accountPromises = account.sites.map(async (site) => {
          const hasDropmarking = await siteHasDropmarking(site.site_id);
          const img = await getBuildingImage(site.site_id);
          return {
            accountName: account.account_name,
            buildingImage: img,
            hasDropmarking,
            ...site,
          };
          // }
        });
        const buildings = await Promise.all(accountPromises);
        return buildings.filter((building) => building !== null);
      });

      const addAdHocSites = await getAdHocSites();
      const buildingsArray = (await Promise.all(promises)).flat();

      setBuildings([...buildingsArray, ...addAdHocSites]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const siteHasDropmarking = async (siteId) => {
    try {
      const siteRef = doc(db, "BuildingsX", siteId);
      const siteData = await getDoc(siteRef);

      if (!siteData.exists()) {
        throw new Error("No data was found.");
      }
      return siteData.data().has_dropmarking;
    } catch (error) {
      console.log(error);
    }
  };

  const getBuildingImage = async (siteId) => {
    try {
      const buildingPrivateDocRef = doc(
        db,
        "BuildingsX",
        siteId,
        "private_data",
        "private"
      );
      const buildingPrivateData = await getDoc(buildingPrivateDocRef);

      if (!buildingPrivateData.exists()) {
        throw new Error("No data was found.");
      }
      return buildingPrivateData.data().buildingImage;
    } catch (error) {
      console.log(error);
    }
  };

  const buildingHandler = (building) => {
    dispatch(
      buildingListActions.setSelectedBuilding({
        ...building,
        name: building.site_id,
      })
    );
    navigation.navigate("Category");
  };

  const toggleAdhocSwitch = () => {
    setFilterAdhocSites((prevState) => !prevState);
  };

  if (loading)
    return (
      <ActivityIndicator style={{ flex: 1 }} size="large" color="#5e99fa" />
    );

  return (
    <View style={styles.container}>
      <View style={styles.searchBox}>
        <TextInput
          placeholder="Search"
          style={styles.searchInput}
          placeholderTextColor="white"
          onChangeText={setSearchedBuilding}
        />
      </View>

      <ScrollView>
        <View style={styles.allBuildingsBox}>
          <View style={styles.nameAndSwitchBox}>
            <Text style={styles.recentlyViewText}>All Buildings</Text>

            <View style={styles.adhocSwitch}>
              <Text style={{ color: "#fff" }}>Adhoc</Text>
              <Switch
                trackColor={{ false: "#E3E3E2", true: "#F3C29A" }}
                thumbColor={filterAdhocSites ? "#FF963F" : "#f4f3f4"}
                onValueChange={toggleAdhocSwitch}
                value={filterAdhocSites}
              />
            </View>
          </View>
          {filteredList.length ? (
            filteredList.map((item, index) => {
              return (
                <ViewBuildingCard
                  key={index}
                  contractName={item.accountName}
                  buildingName={item.site_name}
                  buildingImage={item.buildingImage}
                  goToBuilding={() => buildingHandler(item)}
                />
              );
            })
          ) : (
            <Text style={styles.footerText}>No buildings found</Text>
          )}
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText}>Principle WC</Text>
        </View>
      </ScrollView>
    </View>
  );
}
