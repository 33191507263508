import { Text, TouchableOpacity, StyleSheet } from "react-native";
import { AntDesign } from "@expo/vector-icons";

const UploadPhotoBox = ({ pickMedia, media = [] }) => {
  return (
    <TouchableOpacity style={styles.uploadPhotoBox} onPress={pickMedia}>
      <Text style={styles.uploadPhotoText}>
        Upload a photo: {`${media.length} Selected`}
      </Text>
      <AntDesign name="pluscircle" size={20} color="blue" />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  uploadPhotoBox: {
    marginTop: 10, // remove later
    backgroundColor: "#fff",
    height: 40,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  uploadPhotoText: {
    color: "#000",
    fontWeight: "700",
    marginRight: 5
  },
});

export default UploadPhotoBox;
