import { StyleSheet, Platform } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#5e99fa',

  },
  searchBox: {
    // height: 100,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },

  recentlyViewBox: {
    height: 200,
    marginHorizontal: 20,
    marginBottom: 20
  },
  allBuildingsBox: {
    flex: 1,
    marginHorizontal: 20
  },
  searchInput: {
    color: '#fff',
    flex: 1,
    height: 40,
    border: '1px solid #fff',
    borderRadius: 5,
    paddingHorizontal: 5,
  },
  recentlyViewText: {
    color: '#fff',
    fontSize: 25,
  },
  footer: {
    height: 30,
    margin: 20
  },
  footerText: {
    textAlign: 'center',
    color: '#fff'
  },
  nameAndSwitchBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  adhocSwitch: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5
  },
});
