import {
  doc,
  updateDoc,
  addDoc,
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";
import { firebaseApp } from "../firebase/config";

import { IT_HELPDESK_EMAIL } from "../utils/constants";
import Swal from "sweetalert2";

const db = getFirestore(firebaseApp);

export async function startStopLiveStream(data) {
  try {
    if (typeof data === "string") {
      const liveStreamRef = doc(db, "liveStreaming", data);
      await updateDoc(liveStreamRef, { isLive: false, endedAt: new Date() });
    } else {
      const newDocRef = collection(db, "liveStreaming");
      const newId = await addDoc(newDocRef, data);
      return newId.id;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getAdHocSites() {
  try {
    const adhocSitesRef = collection(db, "adhocSites");
    const adhocSitesSnapshot = await getDocs(adhocSitesRef);
    if (adhocSitesSnapshot.empty) return [];
    const adhocSites = adhocSitesSnapshot.docs.map((doc) => ({
      site_id: doc.id,
      isAdhoc: true,
      hasDropmarking: true,
      site_name : doc.data().name,
    }));
    return adhocSites;
  } catch (error) {
    console.log(error);
  }
}

export async function addAdHocSite(siteName, user) {
  try {
    const newDocRef = collection(db, "adhocSites");
    const newId = await addDoc(newDocRef, {
      name: siteName,
      cleans: 1,
      currentClean: "cleanOne",
      has_dropmarking: true,
      lastCleaned: "",
      lastDropCleaned: "",
    });
    await sendAlertToITHelpdesk(siteName, user);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "New site added successfully!",
    });
    return newId.id;
  } catch (error) {
    console.log(error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong! Please try again.",
    });
  }
}

export async function sendAlertToITHelpdesk(siteName, user) {
  try {
    const newDocRef = collection(db, "mail");
    const newId = await addDoc(newDocRef, {
      to: IT_HELPDESK_EMAIL,
      message: {
        subject: "New Adhoc Site Added",
        html: `Hi DD, <br><br> ${siteName} has been added to the adhocSites collection by ${user}. <br><br> Please check if full site creation is needed. <br><br> Regards, <br><br> WC App ✌🏻`,
      },
    });
    return newId.id;
  } catch (error) {
    console.log(error);
  }
}
