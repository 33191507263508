import { useState } from "react";
import { useSelector } from "react-redux";
import { Text, View } from "react-native";
import AgoraRTC, {
  AgoraRTCProvider,
  useRemoteUsers,
  RemoteUser,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  useJoin,
  usePublish,
  LocalUser,
} from "agora-rtc-react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import userAvatar from "../assets/images/userAvatar.png";

import { startStopLiveStream } from "../api/dropmarking";

const AGORA_APP_ID = "b56c69033df74c03a960571d5b905d6d";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const AgoraLiveWrapper = () => {
  const buildingData = useSelector((state) => state.buildingList.buildingData);
  const liveStreamChannel = buildingData?.liveStreamChannel || null;

  if (!liveStreamChannel) return;

  return (
    <AgoraRTCProvider client={client}>
      <Basics channel={liveStreamChannel} />
    </AgoraRTCProvider>
  );
};

export default AgoraLiveWrapper;

const Basics = ({ channel }) => {
  const [calling, setCalling] = useState(false);

  const selectedBuilding = useSelector(
    (state) => state.buildingList.buildingName
  );
  const siteId = selectedBuilding?.site_id;
  const cleanId = useSelector((state) => state.buildingList.currentClean);
  const dropId = useSelector((state) => state.buildingList.currentDrop?.name);
  const userUid = useSelector((state) => state.auth.uid);

  const [liveStreamingDocId, setLiveStreamingDocId] = useState(null);

  useJoin(
    {
      appid: AGORA_APP_ID,
      channel: channel,
      token: null,
      role: "host",
    },
    calling
  );

  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn, {
    facingMode: "environment",
  });
  usePublish([localMicrophoneTrack, localCameraTrack]);

  const remoteUsers = useRemoteUsers();

  const handleStartStopCall = async () => {
    if (calling) {
      startStopLiveStream(liveStreamingDocId);
      setLiveStreamingDocId(null);
      setCalling(false);
    } else {
      const liveStreamId = await startStopLiveStream({
        siteId,
        isLive: true,
        host: userUid,
        startedAt: new Date(),
        channel,
        participants: [],
        cleaningInfo: {
          dropId,
          cleanId,
        },
      });
      setLiveStreamingDocId(liveStreamId);
      setCalling(true);
    }
  };

  return (
    <>
      <View style={videoContainerStyles.container}>
        <View style={{ flex: 3 }}>
          <LocalUser
            audioTrack={localMicrophoneTrack}
            cameraOn={cameraOn}
            micOn={micOn}
            videoTrack={localCameraTrack}
            cover={userAvatar}
            mirror={false}
            style={{ transform: "scaleX(-1)" }}
          />
        </View>

        {!!remoteUsers.length && (
          <View style={{ flex: 1, overflowX: "auto" }}>
            {remoteUsers.map((user) => (
              <View
                key={user.uid}
                style={{ height: "100%", width: 150, position: "relative" }}
              >
                {!user.hasAudio && (
                  <View
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      zIndex: 1,
                    }}
                  >
                    <Ionicons name={"mic-off"} size={20} color="white" />
                  </View>
                )}
                <RemoteUser user={user} cover={userAvatar} />
              </View>
            ))}
          </View>
        )}
        <View style={videoContainerStyles.controls}>
          {calling && (
            <>
              <TouchableOpacity
                onPress={() => setMic(!micOn)}
                style={videoContainerStyles.controlButton}
              >
                <Ionicons
                  name={micOn ? "mic" : "mic-off"}
                  size={24}
                  color="white"
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setCamera(!cameraOn)}
                style={videoContainerStyles.controlButton}
              >
                <Ionicons
                  name={cameraOn ? "videocam" : "eye-off"}
                  size={24}
                  color="white"
                />
              </TouchableOpacity>{" "}
            </>
          )}
          <TouchableOpacity
            onPress={handleStartStopCall}
            style={[
              videoContainerStyles.controlButton,
              videoContainerStyles[calling ? "endCall" : "startCall"],
            ]}
          >
            {calling ? (
              <Ionicons name={"call"} size={24} color="white" />
            ) : (
              <Text>Start streaming</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const videoContainerStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
    justifyContent: "center",
  },
  controls: {
    position: "absolute",
    bottom: 30,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    zIndex: 10,
  },
  controlButton: {
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: 15,
    borderRadius: 30,
  },
  endCall: {
    backgroundColor: "red",
  },
  startCall: {
    backgroundColor: "green",
  },
});
