import {useState} from "react";
import { View, TextInput, StyleSheet, Button } from "react-native";
import { addAdHocSite } from "../../api/dropmarking";
import { useSelector } from "react-redux";

const NewAdhocSite = ({ navigation }) => {
  const [siteName, setSiteName] = useState("");
  const user = useSelector((state) => state.auth.name);

  const siteNameIsEmpty = siteName.trim() === ""

  const handleSave = async () => {
    if (!siteNameIsEmpty) {
      await addAdHocSite(siteName, user);
      navigation.navigate("Home");
    }
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        placeholder="Enter new site name"
        value={siteName}
        onChangeText={setSiteName}
      />
      <Button
        title="Save"
        onPress={handleSave}
        disabled={siteNameIsEmpty}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 30,
    gap: 10
  },
  input: {
    height: 40,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
  },
});

export default NewAdhocSite;
