export const EQUIPMENT_FORMS = Object.freeze({
  BMU: {
    pre: {
      questions: [
        {
          question:
            "CHECK GENERAL EXTERNAL CONDITION:",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question:
            "CHECK INTERIOR MAIN CONTROL PANEL",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "CHECK INTERIOR HOUSING (ROOF-CAR)",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "CHECK INTERIOR OF A PLATFORM",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question:
            "SATISFACTORY OPERATION OF SERVICE BRAKE",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "CHECK PLATFORM CONTROL PANEL CONTROLS",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: " NO USUAL NOISES",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "VISUAL INSPECTION OF MECHANICAL FASTENINGS",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "CHECK CABLE WINDER OR POWER PLUG",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "CHECK BMU RUNWAY",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
      ],
    },
    post: {
      questions: [
        {
          question:
            "PLATFORM CONTROL PANEL CONTROLS",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "CHECK RUNWAY COVERS NOT OBSTRUCTING ROUTE",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "ON COMPLETION OF WORKS- IS BMU SAFELY PARKED WITH POWER ISOLATED AND KEY REMOVED",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "ON COMPLETION OF WORKS ARE RUNWAY COVERS BACK IN PLACE",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
      ],
    },
  },
  MEWP: {
    pre: {
      questions: [
        {
          question: "LOLER and Insurance",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "Operative is trained to use equipment",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        { question: "Wheels/tyres", value: "", multipleChoice: ["Yes", "No"] },
        {
          question: "Engine/power source",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        { question: "Hydraulics", value: "", multipleChoice: ["Yes", "No"] },
        {
          question: "Hoses and cables",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "Outtriggers, stabilisers",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "Chassi, boom, and scissor pack",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "Platform or cage",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "Decals and signage",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "Using Ground and Platform controls",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
      ],
    },
    post: {
      questions: [
        {
          question: "Equipment is returned to correct storage location",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question: "Rubbish is removed",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
        {
          question:
            "Any defects that occurred during use have been highlighted to correct personnel and report filed in site pack",
          value: "",
          multipleChoice: ["Yes", "No"],
        },
      ],
    },
  },
});

const PPE_FORM = Object.freeze({
  id: "ppe",
  questions: [
    {
      question: "Steel Toe Shoes",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Full Body Harness",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Restraint Lanyards",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Weather forecast checked",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Uniform (t-shirt, trousers)",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Winter Uniform (fleece, winter jacket etc)",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Disposable gloves",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Winter gloves",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Tools attachment (yellow lanyards)",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Sunglasses",
      value: "",
      multipleChoice: ["All Good", "Damaged", "Missing"],
    },
    {
      question: "Damaged item/s",
      value: "N/A",
    },
    {
      question: "Missing item/s",
      value: "N/A",
    },
    {
      question: "Damage/Missing equipment reported to",
      value: "N/A",
    },
  ],
});

export const getFormNames = () => {
  return Object.keys(EQUIPMENT_FORMS);
};

export const getFormCheckpoints = (form, type) => {
  const formIndex = EQUIPMENT_FORMS.findIndex((f) => f.id === form);
  return EQUIPMENT_FORMS[formIndex][type].questions;
};

export const getPPEFormQuestions = () => {
  return PPE_FORM.questions;
};
